.l-banner {
  @include flexAlign();
  background-attachment: fixed;
  &__title {
    @include f(46px,$c-white,700,0,uppercase);
  }
  height: 350px;
  @include mq($screen-sidr) {
    height:250px;
  }
  @include mq($screen-sm) {
    height:200px;
    &__title {
      @include f(30px);
      transform: translateY(30px);
    }
  }
}