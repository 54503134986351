.instagram {
  &__gallery {
    display: flex;
  }

  &-image {
    position: relative;
    display: flex;
    flex:1;
    &:hover .overlay {
      opacity: 1;
      visibility: visible;
    }
    .overlay {
      position: absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      background-color: rgba($c-dark,.8);
      @include flexAlign();
      opacity: 0;
      visibility: hidden;

      transition: all .25s ease;
      @include mq($screen-sm) {
        flex-direction: column;
      }
    }

    .likes {
      background: url(#{$img}/icons/instagram-likes.png) no-repeat left;
    }
    .comments {
      background: url(#{$img}/icons/instagram-comments.png) no-repeat left;
    }
    .likes,.comments {
      @include f(10px,$c-white,700);
      padding: .3rem 0 .3rem 35px;
      margin: .5rem;
      background-size: contain;
    }

    @include mq($screen-sidr) {
      &:nth-child(n+5) {
        display: none;
      }
    }
  }
}