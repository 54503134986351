.slider {
  
  &__inner {
    position: relative;

    .prev, .next {
      display: inline-block;
      position: absolute;
      top: calc(50% - 40px);
      @include size(30px);
      background: $c-gray;
      border-radius: 50%;
      cursor: pointer;
      @include f(25px,$c-gray-dark,0,30px);
      text-align: center;
    }
    .prev {
      left: -50px;
    }
    .next {
      right: -50px;
    }
    @include mq($screen-lg) {
      .prev {
        left: -37px;
      }
      .next {
        right: -37px;
      }
    }
    @include mq($screen-md) {
      .prev,.next {
        display: none;
      }
    }
  }
  
  .slide {
    &__image {

      img {

      }
    }

    &__content {

    }

    &__title {
      @include f(36px,$c-black,500,1.1em,uppercase);
    }

    &__subtitle {
      @include f(36px,$c-black,500,1.1em,uppercase);
    }

  }

  .owl-dots {
    position: absolute;
    bottom: 20px;
    right: 20px;

    .owl-dot span {
      transition: all .3s ease;
      @include size(16px);
      border: none;
      border-radius: 50%;
      margin: 0 5px;
      background: $c-theme;
    }

    .owl-dot span:hover,
    .owl-dot.active span {
      background: $c-white;
    }
  }

}