.p-contact {
  .l-footer__caption {
    color: #000;
    img {
      filter: invert(1);
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .l-footer__link {
    font-size: 21px;
    @include mq($screen-lg) {
      font-size: 17px;
    }
  }
}