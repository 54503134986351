.c-product {

  margin-bottom:30px;

  &__title {
    @include f(25px,$c-black,0,0,uppercase);
    margin-bottom:10px;
    @include mq($screen-sm) {
      @include f(19px)
    }
  }

  &__left {
    width:38%;
    display: inline-block;
    vertical-align: top;
    @include mq($screen-md) {
      width:60%;
      display: block;
      margin:auto;
    }
  }

  &__right {
    width:59%;
    display: inline-block;
    padding:10px 20px;
    @include mq($screen-md) {
      width:100%;
      padding:10px 0;
    }
  }
  &__right {

  }

  &__image {
    position: relative;
    img {
      transition: all .25s ease;
      opacity:0;
      &.current {
        opacity:1;
      }
      &:not(:first-child) {
        position: absolute;
        top:0;
        left:0;
      }
    }
    a {
      &:not(:first-child) img {
        position: absolute;
        top:0;
        left:0;
      }
    }
  }

  &__colors {
    text-align: center;
    margin: 15px 0 5px;
    button {
      @include size(10px);
      border-radius: 50%;
      margin:5px;
      transition: all .25s ease;

      &.current {
        transform: scale(1.5);
      }
    }
  }

  &__serial {
    position: relative;
    @include f(16px,$c-font,600,1.7em);
    text-align: center;
    span {
      opacity:0;
      &.current {
        opacity:1;
      }
      &:not(:first-child) {
        position: absolute;
        top:0;
        left:0;
        right:0;
        text-align: center;
      }
    }
  }

  &__label {
    @include f(16px,$c-font,600,1.7em);
  }
  &__val {
    @include f(16px,$c-theme,700,1.7em);
  }
  @include mq($screen-lg) {
    &__label, &__val {
      @include f(14px)
    }
  }
}