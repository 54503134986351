.pagination {
	$_size: 10px;
	$_border-radius: 0;
	$_border-color: transparent;
	$_color: $c-font;
	$_background: $c-white;

	$_hover-background: transparent;
	$_hover-border-color: transparent;
	$_hover-color: $c-theme;
	
	$_active-background: transparent;
	$_active-border-color: transparent;
	$_active-color: $c-font;

	$_font-size: 15px;



	> li:first-child > a,
	> li:first-child > span,
	> li:last-child > a,
	> li:last-child > span { border-radius: $_border-radius; }

	li a,
	li span {
		@include size($_size);
		margin: 0 5px;
		border-radius: $_border-radius;
		border-color: transparent;
		padding: 0;
		@include f($_font-size,$_color,0,$_size);
		text-align: center;
	}

	> li > a:focus, 
	> li > a:hover, 
	> li > span:focus, 
	> li > span:hover {
		border-color: $_hover-border-color;
		color: $_hover-color;
		background: $_hover-background;
	}

	> .active > a,
	> .active > a:focus,
	> .active > a:hover,
	> .active > span,
	> .active > span:focus,
	> .active > span:hover {
		border-color: $_active-border-color;
		color: $_active-color;
		font-weight:900;
		background: $_active-background;
	}

	> .disabled > a,
	> .disabled > a:focus,
	> .disabled > a:hover,
	> .disabled > span,
	> .disabled > span:focus,
	> .disabled > span:hover {
		border-color: $_border-color;
		color: $_color;
		background: $_background;
	}
}