.l-video {
  overflow: hidden;
  max-height: 890px;
  video {
    width: 100%;
    transition: all .25s ease-in-out;
    opacity:0;
    position:fixed;
    z-index: -2;
  }
}