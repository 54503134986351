.header { 

	&--fixed {
		position: fixed;
		top:0;
		left:0;
		right:0;
		z-index: 99;
	}

	background: rgba($c-black, 1);
	padding: 5px 0;
	position: relative;

	&__social {
		position: absolute;
		top:50%;
		right:20px;
		transform:translateY(-50%);
		display: flex;
		align-items: center;
		justify-content: flex-end;
		z-index: 22;
		a {
			margin-left: 10px;

			@include mq($screen-sm) {
				margin-left: 5px;
			}
		}
		@include mq($screen-sm) {
			right: 10px;
		}
	}

	&__right {
		text-align: right;
	}

	.logo {
		text-align: center;
		margin-top: 10px;
		@include mq($screen-sm) {
			text-align: left;
			margin-left:50px;
		}
	}

}