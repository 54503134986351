.l-footer {
  padding:0;
  background-image: url(#{$img}/main/footer.jpg);
  background-attachment: fixed;
  background-position: bottom;

  &__logo {
    text-align: center;
    @include f(19px,$c-white,400,0,0,$ff-roboto);
    padding:180px 0;
  }

  &__title {
    margin-bottom:30px;
    @include f(16px,$c-white,700,0,uppercase);
  }

  &__caption {
    padding-left: 40px;
    position: relative;
    margin-bottom:15px;
    @include f(15px,$c-white);

    img {
      position: absolute;
      top:0;
      left:0;
    }

    &.no-icon {
      padding-left: 0;
    }
    &.separator {
      margin-bottom: 50px;
    }
    @include mq($screen-xs) {
      @include f(14px);
    }
  }

  &__column {
    padding:110px 50px 73px;
    background-color: rgba($c-black,.82);
    strong {
      display: block;
    }

    @include mq($screen-lg) {
      padding-top:89px;
    }
    @include mq($screen-sm) {
      padding:50px;
      margin:0 -5px;
    }
    @include mq($screen-xs) {
      padding:30px;
    }
  }

  &__link {
    @include f(21);
    font-weight:700;
  }

  &__cpr {
    @include f(13px,$c-cpr,400,0,0,$ff-roboto);
  }
}
