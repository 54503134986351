.p-products {
	&__inner {
		padding:50px 70px;
	}
	&__desc {
		margin-bottom:30px;
	}
	@include mq($screen-lg) {
		&__inner {
			padding:50px 40px;
		}
	}
	@include mq($screen-sm) {
		&__inner {
			padding:30px;
		}
	}
}

.p-product {
	&__title {
		@include f(25px);
		margin-bottom:20px;
		@include mq($screen-sm) {
			@include f(20px)
		}
	}

	&__image {
		img {
			width:100%;
			padding:10px;
			margin-bottom:10px;
		}
	}

	&__text {
		margin-top:30px;
		h3 {
			@include f(16px,$c-black,700);
		}
	}
}