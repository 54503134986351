.c-tabs {

  ul {
    list-style-type: none;
    display: flex;
    li {
      width: percentage(1/3);
      margin-bottom:10px;
      a {
        width:100%;
        display: block;
        padding: 0 10px;
        text-align: center;
        @include f(17px,$c-font,400,55px,uppercase);
        background-color: #fff;
        &:hover {
          color:$c-theme;
        }
      }

      &.ui-state-active a {
        color:$c-white;
        background-color: $c-theme;
      }
      &.ui-state-focus a {
        &:focus {
          outline:none!important;
        }
      }
    }
  }
}