.cookies {
	$_background: rgba($c-theme,.9);
	$_color: $c-black;
	$_btn-background: $c-white;
	$_btn-color: $c-font;

	transition: all .4s ease;
	position: fixed;
	z-index: $z-index-cookies;
	bottom: 15px;
	left: 15px;
	width: calc(100% - 30px);
	margin: 0;
	padding: 20px 0 10px;
	@include f(12px,$_color,400);
	text-align: justify;
	background: $_background;
	box-shadow: 0 1px 6px rgba($c-black,.3);

	@include mq($screen-sm) {
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 15px 0 5px;
		@include f(9px);
		box-shadow: 0 -1px 6px rgba($c-black,.3);
	}

	&.is-closed {
		opacity: 0;
		visibility: hidden;
		@include scale(0.8);
	}

	&__content {

	}

	&__left {
		float: left;
		width: 30px;


		@include mq($screen-sm) {
			display: none;
		}
	}

	&__right {
		float: right;
		text-align: right;


		@include mq($screen-sm) {
			float: right;
			padding-left: 15px;
		}
	}

	&__center {
		overflow: hidden;
		padding: 0 30px 0 15px;


		@include mq($screen-sm) {
			float: none;
			padding: 0;
		}
	}

	&__button {
		transition: all 0.25s ease;
		display: inline-block;
		margin: 0;
		border: none;
		border-radius: 2px;
		padding: 0 20px;
		@include f(14px,$_btn-color,500,35px);
		background: $_btn-background;

		@include mq($screen-sm) {
			padding: 0 10px;
			@include f(11px,0,0,30px);
		}

		&:hover {
			color: $_btn-color;
			background: $_btn-background;
			box-shadow: 0 1px 6px rgba($c-black,.3);
		}
	}
}
